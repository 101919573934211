import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: home
	},
	{
		path: '/team',
		name: 'team',
		component: () => import('@/views/team')
	},
	{
		name: 'teamDetails',
		path: '/team-details/:id',
		props: true,
		component: () => import('@/views/teamDetails')
	},
	{
		name: 'pipeline',
		path: '/pipeline',
		component: () => import('@/views/pipeline')
	},
	{
		path: '/career',
		name: 'career',
		component: () => import('@/views/career')
	},
	// {
	// 	path: '/careerDetails',
	// 	name: 'careerDetails',
	// 	component: () => import('@/views/careerDetails')
	// },
	{
		name: 'contact',
		path: '/contact',
		component: () => import('@/views/contact')
	},
	{
		path: '/platform',
		name: 'platform',
		component: () => import('@/views/platform')
	},
        {
                path: '/publications',
                name: 'publications',
                component: () => import('@/views/publications')
        },
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news')
	},
	{
		path: '/news-details/1',
		name: 'newsDetails1',
		component: () => import('@/views/newsDetails1')
	},
        {
                path: '/news-details/5',
                name: 'newsDetails5',
                component: () => import('@/views/newsDetails5')
        },
        {
                path: '/news-details/8',
                name: 'newsDetails8',
                component: () => import('@/views/newsDetails8')
        },
        {
                path: '/news-details/9',
                name: 'newsDetails9',
                component: () => import('@/views/newsDetails9')
        },
        {
                path: '/news-details/10',
                name: 'newsDetails10',
                component: () => import('@/views/newsDetails10')
        },
        {
                path: '/news-details/11',
                name: 'newsDetails11',
                component: () => import('@/views/newsDetails11')
        },
        {
                path: '/news-details/12',
                name: 'newsDetails12',
                component: () => import('@/views/newsDetails12')
        },
        {
                path: '/news-details/13',
                name: 'newsDetails13',
                component: () => import('@/views/newsDetails13')
        },
        {
                path: '/news-details/14',
                name: 'newsDetails14',
                component: () => import('@/views/newsDetails14')
        },
        {
                path: '/news-details/15',
                name: 'newsDetails15',
                component: () => import('@/views/newsDetails15')
        },
	{
                path: '/news-details/4',
                name: 'newsDetails4',
                component: () => import('@/views/newsDetails4')
        }
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.NODE_ENV === "development" ? '/' : '/',
	routes
})
router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	next()
})

export default router
