<template>
  <div class="footer">
    <div class="d-flex justify-content-between">
      <img class="logo" :src="footer_logo" alt="">
      <img width="100px" height="100px" class="p-0" :src="barcode" alt="">
    </div>
    <div class="d-flex justify-content-between mt-2">
      <div class="text-white">
        &copy;2021 GV20 Therapeutics All Rights Reserved.
      </div>
      <div class="d-flex col-2  justify-content-end p-0">
        <a href="https://www.linkedin.com/company/gv20therapeutics">
          <img class="icon mr-1" :src="li" alt="">
        </a>
        <!-- <img class="icon" :src="tw" alt=""> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      footer_logo: require('../assets/bottom_logo.svg'),
      barcode: require('../assets/barcode.jpg'),
      li: require('../assets/linkedin.png'),
      tw: require('../assets/twitter.png'),
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  padding: 60px 20px;
  font-size: 40px;
  background-color: #003e64;
  .logo {
    width: 220px;
    height: 100px;
  }
  .icon {
    width: 64px;
    height: 64px;
  }
}
</style>
