<template>
  <div class="headers">
    <nav>
      <div class="logo" @click="handlerClick">
        <router-link to="/">
          <img :src="logo" alt="">
        </router-link>
      </div>
      <div class="menu">
        <label for="checkd">
          <img :src="menu" alt="">
        </label>
      </div>
      <input v-model="che" id="checkd" type="checkbox">
      <div class="menus">
        <div v-for="item in menus" :key="item.title">
          <div @click="handlerClick">
            <router-link class="text-dark" :to="item.link">
              {{item.title}}
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Headers',
  data () {
    return {
      logo: require('../assets/Mobile_topbar_Logo.svg'),
      menu: require('../assets/top_nav.png'),
      menus: [
	{
          title: 'Home',
          link: '/'
        },
        {
          title: 'Pipeline',
          link: '/pipeline'
        },
        {
          title: 'Platform',
          link: '/platform'
        },
        {
          title: 'Team',
          link: '/team',
        },
        {
         title: 'Careers',
         link: '/career'
        },
        {
          title: 'News',
          link: '/news'
        },
        {
          title: 'Publications',
          link: '/publications'
        },
        {
          title: 'Contact',
          link: '/contact'
        }
      ],
      che: false
    }
  },
  methods: {
    handlerClick () {
      this.che = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.headers {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    .logo {
      width: 200px;
    }
    .menu {
      width: 60px;
    }
    #checkd {
      position: absolute;
      display: none;
      &:checked ~ .menus {
        transform: none;
        max-height: 4000px;
        opacity: 1;
      }
    }
    .menus {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateX(100%);
      transition: 0.33s ease;
      position: absolute;
      width: 100%;
      background-color: #fff;
      bottom: -700px;
      left: 0;
      padding-left: 50px;
      & > div {
        display: flex;
        align-items: center;
        height: 100px;
      }
      .text-dark {
        font-size: 40px;
        font-weight: bold;
      }
    }
    img {
      width: 100%;
    }
  }
}
</style>
