<template>
  <div class="home">
    <div ref="box_list" class="box_list ">
      <div ref="box_home" class="box_home text-center col-12 d-flex align-items-center justify-content-center">
        <div><img :src="logo" alt=""></div>
        <!-- <div class="text_1">
          Crack the antibody drug discovery codeand bring effective immunotherapy drugs
          to cancer patients
        </div> -->
      </div>
      <div ref="box_1" class="box_1">
        <div class="title text-white">Integrating AI, genomics, and cancer biology to create revolutionary antibody therapeutics</div>
        <!--<div class="text_1 text-white">Novel drug targets <br /> Functional antibodies</div>-->
      </div>
      <div ref="box_2" class="box_comment box_2">
        <div class="title">Our unique STEAD (Simultaneous Target Evaluation and Antibody Discovery) platform is the foundation of our drug discovery and development efforts</div>
        <!--<div class="text_2">
          <div>2018 Allison & Honjo Nobel Prize</div>
          <div>2014 Anti-PD1 FDA approval</div>
          <div>2011 Anti-CTLA4 FDA approval</div>
        </div>-->
      </div>
      <div ref="box_3" class="box_comment box_3">
        <div class="title">
          We are developing a differentiated pipeline of innovative antibody therapeutics that will potentially transform how cancers are treated
        </div>
        <!--<div class="text_2">
          <div>2020 AlphaFold in structure prediction</div>
          <div>2012 Google Cat in object recognition</div>
          <div>2006 lmageNet in image annotation</div>
        </div>-->
      </div>
      <div ref="box_4" class="box_comment box_4">
        <div class="title">
          We set ourselves apart by deeply integrating AI, genomics, and cancer biology. Collectively we connect the dots
        </div>
        <!--<div class="text_2">
          <div>2014 CRISPR screen</div>
          <div>2009 Single-cell RNA-seq</div>
          <div>2007 Next-generation sequencing</div>
        </div>-->
      </div>
    </div>

    <!--<div class="means">
      <div class="m_con">
        <div class="title">
          FINDING CANCER
          <br />
          VULNERABILITIES
        </div>
        <div class="text_2">
          We are dedicated to finding
          <br />
          better cancer drugs with
          <br />
          faster speed and lower cost.
        </div>
      </div>
    </div>
    <div class="big_data">
      <div class="list">
        <div class="item mb-2">
          <div><img :src="big_data[1]" alt=""></div>
          <div class="text">
            GV20 Genomics
            <br />
            Platform
          </div>
        </div>
        <div class="item mt-2">
          <div><img :src="big_data[0]" alt=""></div>
          <div class="text">
            GV20 Al
            <br />
            Platform
          </div>
        </div>
      </div>
    </div>
    <div class="more">
      <div class="m_list">
        <div class="item">
          <i class="fa fa-caret-right"></i>
          <p>Novel Drug Targets</p>
        </div>
        <div class="item">
          <i class="fa fa-caret-right"></i>
          <p>Best Functional Antibody Candidates</p>
        </div>
        <div class="item">
          <i class="fa fa-caret-right"></i>
          <p>Effective Cancer <br /> lmmunotherapy Drugs</p>
        </div>
      </div>
      <router-link to="/platform">
        <div class="m_btn">Explore more</div>
      </router-link>
    </div>
    -->
    <div class="slide" style="padding-top: 0.5rem;">
      <div class="text_1 font-weight-bold">Our Investors</div>
      <div class="swiper mt-2">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="d-flex justify-content-center">
            <div class="img">
              <img :src="slide_list[0]" alt="">
            </div>
            <div class="img">
              <img :src="slide_list[1]" alt="">
            </div>

          </swiper-slide>
          <swiper-slide class="d-flex justify-content-center">
            <div class="img">
              <img :src="slide_list[2]" alt="">
            </div>

            <div class="img">
              <img :src="slide_list[3]" alt="">
            </div>

          </swiper-slide>
          <swiper-slide>
            <div class="img">
              <img :src="slide_list[4]" alt="">
            </div>

          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="cards">
      <div class="title font-weight-bold">News</div>
      <div v-for="(item,index) in cards" :key="item.info" class="card">
        <!--<img class="card-img-top" :src="item.img" alt="Card image" style="width:100%">-->
        <div class="card-body p-1">
          <p class="card-text font-weight-bold">{{item.info}}</p>
          <div class="item d-flex justify-content-between align-items-center">
            <span class="time">{{item.time}}</span>

            <div v-show="index == 0 ? true :false">
              <a href="https://www.prnewswire.com/news-releases/gv20-therapeutics-to-present-clinical-data-on-gv20-0251-at-the-esmo-congress-2024-302225841.html">
                <button class="btn p-1">Read More</button>
              </a>
            </div>

            <div v-show="index == 1 ? true :false">
              <a href="https://www.linkedin.com/posts/gv20tx_oncology-biotech-ai-activity-7201613010379948033-Pim2/">
                <button class="btn p-1">Read More</button>
              </a>
            </div>

            <div v-show="index == 2 ? true :false">
              <a href="https://www.prnewswire.com/news-releases/gv20-therapeutics-to-present-at-the-jefferies-healthcare-conference-private-biotech-day-302152897.html">
                <button class="btn p-1">Read More</button>
              </a>
            </div>

            <!--<div v-show="index == 2 ? true :false">
              <router-link :to="{name:'newsDetails15'}">
                <button class="btn p-1">Read More</button>
              </router-link>
            </div>-->

            <!--<div v-show="index == 4 ? true :false">
              <a href="https://www.businesswire.com/news/home/20220720005122/en/GV20-Therapeutics-Announces-Appointment-of-Three-Advisers">
                <button class="btn p-1">Read More</button>
              </a>
            </div>-->

          </div>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>

<script>
import Footers from '@/components/Footers'
export default {
  name: 'Home',
  data () {
    return {
      logo: require('../assets/logo.png'),
      big_data: [require('../assets/genomicsplatform_icon.png'), require('../assets/aiplatform_icon.png')],
      slide_list: [
        require('../assets/vc2.png'),
        require('../assets/vc1.png'),
        require('../assets/vc3.png'),
        require('../assets/vc5.png'),
        require('../assets/vc4.png'),
      ],
      swiperOptions: {
        slidesPerView: 1,//显示个数
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: true
      },
      scrollY: 0,
      cards: [
        {
          info: "GV20 Therapeutics to Present Clinical Data on GV20-0251 at the ESMO Congress 2024",
          time: "Aug 21, 2024",
          detail: "https://www.prnewswire.com/news-releases/gv20-therapeutics-to-present-clinical-data-on-gv20-0251-at-the-esmo-congress-2024-302225841.html",
          img: require("../assets/news1.jpg")
        }, {
          info: "Flavian D. Brown, Our Principal Scientist, was Selected for the Posse Alumni Category",
          time: "May 31, 2024",
          detail: "https://www.linkedin.com/posts/gv20tx_oncology-biotech-ai-activity-7201613010379948033-Pim2/",
          img: require("../assets/news1.jpg")
        }, {
          info: "GV20 Therapeutics to Present at the Jefferies Healthcare Conference Private Biotech Day",
          time: "May 22, 2024",
          detail: "https://www.prnewswire.com/news-releases/gv20-therapeutics-to-present-at-the-jefferies-healthcare-conference-private-biotech-day-302152897.html",
          img: require("../assets/news1.jpg")
        },
      ]
    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
$padding-left: 20px;
$margin-bottom: 150px;
.home {
  .box_list {
    margin-bottom: $margin-bottom;
    position: relative;
    min-height: 1960px;
    [class^='box_'] {
      padding-left: $padding-left;
    }
    &.active {
      min-height: 3000px;
    }
    .box_home {
      position: absolute;
      padding: 50px;
      height: 520px;
      background: url(../assets/graygradient_bg.jpg) no-repeat center
        center/cover;
      transform: translateY(-100%);
      transform-origin: center top 0;
      transition: 0.33s ease;
      z-index: 1;
    }

    .box_1,
    .box_2,
    .box_3,
    .box_4 {
      position: absolute;
      width: 100%;
      transform-origin: center top 0;
      transition: 0.23s ease;
    }
    .box_2 {
      top: 560px;
      transition: 0.4s ease;
    }
    .box_3 {
      top: calc(540px * 2);
      transition: 0.6s ease;
    }
    .box_4 {
      top: calc(530px * 3);
      transition: 0.8s ease;
    }
    .hide {
      top: -100%;
      // transform: translateY(-100%);
    }
    .show {
      transform: none;
    }
    .box_1 {
      height: 556px;
      background: url(../assets/banner_bg.jpg) no-repeat center center/100% 100%;
      padding-top: 80px;
    }
    .box_comment {
      height: 520px;
      padding-top: 80px;
      .title {
        line-height: 60px;
      }
    }
    .box_2 {
      background: url(../assets/CancerImmunology_bg.jpg) no-repeat center
        center/100% 100%;
    }
    .box_3 {
      background: url(../assets/BigDataandAI_bg.jpg) no-repeat center
        center/100% 100%;
    }
    .box_4 {
      background: url(../assets/High-throughput_bg.jpg) no-repeat center
        center/100% 100%;
    }
  }
  .means {
    .m_con {
      padding: 50px 0 50px $padding-left;
      background: #f6f8f9;
    }
    .end {
      margin-top: $margin-bottom;
      margin-bottom: 30px;
      padding-left: $padding-left;
    }
    .text_1 {
      font-weight: 500;
      font-size: 60px;
    }
    .title {
      font-size: 70px;
    }
  }
  .big_data {
    display: flex;
    justify-content: flex-end;
    height: 600px;
    background: url(../assets/solution_green_bg.jpg) no-repeat center
      center/cover;
    img {
      width: 150px;
    }
    .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 70%;
    }
    .item {
      display: flex;
      align-items: center;
    }
    .text {
      padding-left: 20px;
      color: #fff;
      font-size: 40px;
    }
  }
  .more {
    padding-left: $padding-left;
    height: 600px;
    background: url(../assets/solution_purple_bg.jpg) no-repeat center
      center/cover;
    .m_list {
      padding: 88px 0 70px 0;
      .item {
        display: flex;
        color: #fff;
        margin: 5px 0;
        .fa {
          padding-right: 40px;
        }
        p {
          font-size: 40px;
          margin: 0;
        }
      }
    }
    .m_btn {
      width: 400px;
      height: 110px;
      line-height: 110px;
      color: #415db6;
      font-size: 38px;
      font-weight: bold;
      text-align: center;
      background: #fff;
      border-radius: 73px;
    }
  }
  .slide {
    margin: 50px 0;
    padding-left: $padding-left;
    .img {
      width: 45%;
      margin: 20px;
      padding: 50px;
      height: 300px;
      border: 2px solid #ccc;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
    /deep/.swiper-pagination-bullet-active {
      background: #000;
    }
  }
  .cards {
    padding: $padding-left;
    margin-bottom: 60px;
    .title {
      margin-bottom: 30px;
    }
    .card {
      border-radius: 0;
      box-shadow: 4px 8px 20px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
      img {
        border-radius: inherit;
      }
      .time {
        color: #6d7278;
      }
      .btn {
        font-size: 40px;
        font-weight: bold;
      }
    }
    .card-body {
      font-size: 40px;
    }
  }
  .title {
    font-size: 50px;
    font-weight: bold;
  }
  .text_1 {
    margin-top: 40px;
    font-size: 40px;
  }
  .text_2 {
    color: #032b45;
    font-size: 35px;
    margin-top: 70px;
    font-weight: 300;
    line-height: 60px;
  }
  /deep/ .swiper-container {
    padding-bottom: 100px;
  }
  /deep/ .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
  }
}
</style>
