import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'

import 'amfe-flexible'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'

Vue.config.productionTip = false

Vue.use(
	VueAwesomeSwiper /* { default options with global component } */
)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
