<template>
  <div id="app">
    <Headers />
    <div class="con">
      <router-view/>
    </div>
  </div>
</template>
<script>
import Headers from '@/components/Headers'
export default {
  components:{
    Headers
  }
}
</script>
<style lang="scss">
.con{
  margin-top: 180px;
}
</style>
